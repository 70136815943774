<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText || "Información actualizada correctamente." }}
    </v-snackbar>

    <v-overlay class="loading-center" :value="saving">
      <!-- <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      /> -->
         <v-progress-circular
      :width="3"
      color="primary"
      indeterminate
    ></v-progress-circular>
    </v-overlay>

    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="7" md="7">
          <h1>
            Descuentos de
            <span class="font-weight-light">
              {{ getBusinessName }}
            </span>
          </h1>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-3">
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="5">
              <p>Tipo de descuento</p>
              <v-autocomplete
                v-model="discount.isFixed"
                :items="amountType"
                placeholder="Seleccione tipo de monto"
                outlined
                item-value="value"
                item-text="text"
                rounded
                class="mt-6"
              />
            </v-col>

            <v-col cols="12" md="5">
              <p>Monto del descuento</p>
              <input
                class="control-input control-input-number mt-6"
                type="number"
                placeholder="Ingrese el monto"
                v-model.number="discount.amount"
              />
            </v-col>

            <v-col cols="12" class="mt-3" md="2">
              <v-btn
                block
                @click="newPrices()"
                class="save-btn mt-10"
                color="primary"
              >
                Aplicar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col style="text-align:end" class="mb-5" cols="12">
          <v-btn
            color="primary"
            class="white--text mt-5"
            @click="
              addProductDialog = true;
              randomKey = Math.random();
            "
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Vincular o desvincular productos
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="getDiscountProducts"
            :items-per-page="5"
            :loading="loading"
            :sort-by="['position']"
            :sort-desc="[false, true]"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50, 100, 250],
            }"
          >
            <template v-slot:[`item.images`]="{ item }">
              <v-avatar>
                <img
                  style="object-fit: cover;"
                  :src="
                    item.images[0] ||
                      'https://firebasestorage.googleapis.com/v0/b/el-ocho-data/o/product-default.jpg?alt=media&token=2936fc1f-b1de-49f8-81dd-8ad132ef52df'
                  "
                  :alt="item.name"
                />
              </v-avatar>
            </template>

            <template v-slot:[`item.isDiscount`]="{ item }">
              <v-switch
                @change="
                  switchControlChanged(
                    { isDiscount: item.isDiscount },
                    item['.key'],
                    item,
                    'isDiscount'
                  )
                "
                v-model="item.isDiscount"
              ></v-switch>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
            </template>

            <template v-slot:[`item.discount`]="{ item }">
              {{ (item.discountPrice - item.price) | currency }}
            </template>

            <template v-slot:[`item.discountPercentage`]="{ item }">
              {{ item.discountPercentage }} %
            </template>

            <template v-slot:[`item.price`]="{ item }">
              {{ item.price | currency }}
            </template>

            <template v-slot:[`item.discountPrice`]="{ item }">
              <v-text-field
                @keyup="checkPercentage($event, item)"
                v-model.number="item.discountPrice"
                class="new-price"
                type="number"
                required
              ></v-text-field>
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position:relative;">
                <div
                  style="position:absolute; z-index:999; width:100%; height:100%; display:flex; flex-direction:column;justify-content:center; align-items:center;"
                >
          <v-progress-circular
      :width="3"
      color="primary"
      indeterminate
    ></v-progress-circular>
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                >
                </v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="mt-5" style="text-align:end" cols="12">
          <!-- <v-divider></v-divider> -->
          <v-btn
            @click.prevent="updateDiscountsProducts"
            class="mt-4 save-btn"
            color="primary"
            dark
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      persistent
      v-if="addProductDialog"
      v-model="addProductDialog"
      max-width="700px"
    >
      <add-remove-products
        :key="randomKey"
        :products="products"
        @success="handleSuccess"
        @cancel="addProductDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import { mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import addRemoveProducts from "./add-remove-products";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "discounts",
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    addRemoveProducts,
  },
  data() {
    return {
      business: null,
      item: 0,
      randomKey: 0,
      products: [],
      productsCopy: [],
      discount: {
        isFixed: false,
        amount: 10,
      },
      itemToDelete: null,
      itemToEdit: "",
      amountType: [
        { text: "Porcentual", value: false },
        { text: "Fijo", value: true },
      ],
      modalDelete: false,
      modalAddDiscount: false,
      addProductDialog: false,
      headers: [
        {
          text: "Código",
          value: "barcode",
        },
        {
          text: "Imágen",
          value: "images",
        },
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Precio original",
          value: "price",
          align: "center",
        },
        {
          text: "% Descuento",
          value: "discountPercentage",
          align: "center",
        },
        {
          text: "Precio en descuento",
          value: "discountPrice",
          width: "150px",
          align: "center",
        },
        {
          text: "Diferencia en precio",
          value: "discount",
          align: "center",
        },
        {
          text: "Aplicar descuento",
          value: "isDiscount",
          align: "center",
        },
      ],
      loading: true,
      snackbarText: "",
      snackbar: "",
      saving: false,
    };
  },
  watch: {
    selectedBusiness() {
      this.products = [];
      this.getBusinessData();
    },
  },
  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";

      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("LL");
    },
    currency: function(amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
    getBusinessName() {
      return this.$store.state.selectedBusiness.shortName;
    },
    getDiscountProducts() {
      return this.products.filter((item) =>
        item.hasOwnProperty("discountPrice")
      );
    },
  },
  methods: {
    moment,
    checkPercentage(e, item) {
      item.discountPercentage =
        item.price > 0
          ? parseFloat(100 - (item.discountPrice / item.price) * 100).toFixed(2)
          : 0;
      item.discount = item.price > 0 ? item.discountPrice - item.price : 0;
    },
    getTime() {
      let timeArr = moment()
        .tz("America/Tegucigalpa")
        .format("YYYY, MM, DD, HH, mm, ss, 0")
        .split(",");
      return new Date(
        parseInt(timeArr[0]),
        parseInt(timeArr[1]) - 1,
        parseInt(timeArr[2]),
        parseInt(timeArr[3]),
        parseInt(timeArr[4]),
        parseInt(timeArr[5]),
        parseInt(timeArr[6])
      );
    },
    addDiscountProduct(product, productCopy) {
      return new Promise((resolve, reject) => {
        db.collection("qrProducts")
          .doc(product[".key"])
          .update({
            isDiscount: product.isDiscount,
            discountPrice: parseFloat(product.discountPrice),
          })
          .then((res) => {
            resolve("success");

            this.createLedger({
              source_id: product[".key"],
              source_type: "products",
              currentValue: {
                isDiscount: product.isDiscount,
                discountPrice: parseFloat(product.discountPrice),
              },
              prevValue: {
                isDiscount: productCopy.isDiscount,
                discountPrice: parseFloat(productCopy.discountPrice),
              },
            });
          });
      });
    },
    removeDiscountProduct(productId) {
      return new Promise((resolve) => {
        let productRef = db.collection("qrProducts").doc(productId);
        console.log("productRef", productRef);
        productRef
          .update({
            isDiscount: fb.firestore.FieldValue.delete(),
            discountPrice: fb.firestore.FieldValue.delete(),
          })
          .then((res) => {
            resolve("success");
          });
      });
    },
    updateCascadeBusiness(businessId, business) {
      db.collection(`cities/${business.city}/sections`)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let sectionId = doc.id;
            let section = doc.data();
            let items = section.target.filter((doc) => {
              return doc.business == businessId;
            });

            items.forEach((elementUpdate) => {
              elementUpdate.isDiscount = business.isDiscount;
              elementUpdate.discountMax = business.discountMax;
            });

            db.collection(`cities/${business.city}/sections`)
              .doc(sectionId)
              .update({
                target: section.target,
              })
              .catch((error) => {
                throw error;
              });
          });
        })
        .catch(console.error);
    },
    async updateDiscountsProducts() {
      this.saving = true;

      let productsAddedKey = this.getDiscountProducts.map((prod) => {
        return prod[".key"];
      });

      //remove discountPrice
      for (let l = 0; l < this.productsCopy.length; l++) {
        let productId = this.productsCopy[l];
        if (!productsAddedKey.includes(productId)) {
          await this.removeDiscountProduct(productId);
        }
      }

      //add discountPrice
      let productsToUpdate = this.getDiscountProducts;
      let isDiscount = productsToUpdate.find((item) => item.isDiscount);
      let discountMax = 0;

      for (let i = 0; i < productsToUpdate.length; i++) {
        let item = productsToUpdate[i];
        let itemCopy = item;
        discountMax =
          item.isDiscount && item.discountPercentage > discountMax
            ? Number(item.discountPercentage)
            : Number(discountMax);
        if (item.discountPrice >= 0) {
          await this.addDiscountProduct(item, itemCopy);
        }
      }

      let businessData = {
        isDiscount: isDiscount ? true : false,
        discountMax,
      };

      db.collection("businesses")
        .doc(this.selectedBusiness[".key"])
        .update(businessData)
        .then(() => {
          if (
            this.selectedBusiness.isLobbySection == true ||
            this.selectedBusiness.isLobbySection == false
          ) {
            this.updateCascadeBusiness(
              this.selectedBusiness[".key"],
              this.selectedBusiness
            );
          }
          this.createLedger({
            source_id: this.selectedBusiness[".key"],
            source_type: "businesses",
            currentValue: businessData,
            prevValue: {
              isDiscount: this.selectedBusiness.isDiscount,
              discountMax: this.selectedBusiness.discountMax,
            },
          });

          this.saving = false;
          this.snackbarText = "Descuentos actualizados exitosamente.";
          this.snackbar = true;
        })
        .catch(() => {
          this.saving = false;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
        });
    },
    handleSuccess(data) {
      this.products = this.products.map((item) => {
        if (item.hasOwnProperty("isDiscount") && !data.includes(item[".key"])) {
          let product = item;
          delete product.isDiscount;
          delete product.discountPrice;
          return product;
        } else if (
          !item.hasOwnProperty("isDiscount") &&
          data.includes(item[".key"])
        ) {
          let product = item;
          product.isDiscount = true;
          product.discountPrice = this.discount.isFixed
            ? parseFloat(product.price - this.discount.amount).toFixed(2)
            : parseFloat(
                product.price - (product.price * this.discount.amount) / 100
              ).toFixed(2);
          product.discountPercentage = parseFloat(
            100 - (item.discountPrice / item.price) * 100
          ).toFixed(2);
          return product;
        } else {
          return item;
        }
      });
      this.addProductDialog = false;
    },

    async switchControlChanged(data, productId, product, field) {
      let prevData = Object.assign({}, data);
      prevData[field] = !data[field];

      if (data && productId) {
        this.saving = true;
        data.modifiedAt = fb.firestore.Timestamp.fromDate(this.getTime());
        data.modifiedBy = this.$store.state.user[".key"];
        db.collection("qrProducts")
          .doc(productId)
          .update(data)
          .then(async () => {
            await this.updateBusinessData();
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;

            this.createLedger({
              source_id: productId,
              source_type: "products",
              currentValue: data,
              prevValue: prevData,
            });
          })
          .catch(() => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            product.isDiscount = !product.isDiscount;
          });
      }
    },
    updateBusinessData() {
      return new Promise((resolve, reject) => {
        let productsToUpdate = this.getDiscountProducts;
        let isDiscount = productsToUpdate.find((item) => item.isDiscount);
        let discountMax = 0;

        for (let i = 0; i < productsToUpdate.length; i++) {
          let item = productsToUpdate[i];
          discountMax =
            item.isDiscount && item.discountPercentage > discountMax
              ? Number(item.discountPercentage)
              : Number(discountMax);
        }

        let businessData = {
          isDiscount: isDiscount ? true : false,
          discountMax,
        };

        db.collection("businesses")
          .doc(this.selectedBusiness[".key"])
          .update(businessData)
          .then((res) => {
            resolve("success");
            this.createLedger({
              source_id: this.selectedBusiness[".key"],
              source_type: "businesses",
              currentValue: businessData,
              prevValue: {
                isDiscount: this.selectedBusiness.isDiscount,
                discountMax: this.selectedBusiness.discountMax,
              },
            });
          })
          .catch((err) => {
            reject("error");
          });
      });
    },
    newPrices() {
      this.getDiscountProducts.forEach((product) => {
        product.discount = this.discount.isFixed
          ? this.discount.amount
          : (product.price * this.discount.amount) / 100;
        product.discountPercentage = this.discount.isFixed
          ? parseFloat((this.discount.amount / product.price) * 100).toFixed(2)
          : parseFloat(this.discount.amount.toFixed(2));
        product.discountPrice = this.discount.isFixed
          ? parseFloat(product.price - this.discount.amount).toFixed(2)
          : parseFloat(
              product.price - (product.price * this.discount.amount) / 100
            ).toFixed(2);
      });
    },
    async getBusinessData() {
      if (!this.selectedBusiness) this.$router.push({ path: "/" });

      this.loading = true;
      this.tagsToSearch = "";
      this.$store.state.search = "";

      let products = this.$binding(
        "products",
        db
          .collection(`qrProducts`)
          .where("business", "array-contains", this.selectedBusiness[".key"])
          .where("deleted", "==", false)
          .where("addon", "==", false)
      );

      await Promise.all([products]);

      this.products = this.products.map((product) => {
        let item = product;
        if (item.discountPrice >= 0) {
          item.discountPercentage =
            item.price > 0
              ? parseFloat(
                  100 - (item.discountPrice / item.price) * 100
                ).toFixed(2)
              : 0;
          item.discount = parseFloat(item.discountPrice - item.price).toFixed(
            2
          );
        }
        return item;
      });

      this.productsCopy = Object.assign([], this.products);
      this.productsCopy = this.productsCopy.filter((item) =>
        item.hasOwnProperty("discountPrice")
      );
      this.productsCopy = this.productsCopy.map((item) => {
        return item[".key"];
      });
      this.loading = false;
    },
  },
  mounted() {
    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "productos en descuentos";
    this.getBusinessData();
  },
};
</script>

<style scoped>
.theme--light.v-list {
  background: white !important;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.fa-times {
  color: gray;
}
.actions {
  position: fixed;
  bottom: 0;
  width: 80%;
  bottom: 20px;
  padding-right: 20px;
  background-color: white;
}
.fixed-position {
  z-index: 100;
}
</style>
